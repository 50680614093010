<nav class="navbar headroom navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/home-twenty">
            <img src="assets/img/small-logo.png" alt="image">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            </ul>
            <div class="others-option">
                <a href="#contact" class="default-btn">Hire Me</a>
            </div>
        </div>
    </div>
</nav>

<div class="main-banner banner-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <span class="sub-title">Hello I'am</span>
                    <h1>Alexander Ludwig</h1>
                    <h6>
                        I’am a
                        <span class="typewrite" data-period="2000" data-type='[ "Photographer", "Landscape Photographer", "Wildlife Photographer", "Aerial Photographer" ]'>
                            <span class="wrap"></span>
                        </span>
                    </h6>
                </div>
            </div>
        </div>
    </div>

    <div class="scroll-btn">
        <a href="#about">
            <span class="mouse">
                <span></span>
            </span>
        </a>
    </div>
</div>

<section id="about" class="about-area about-style-two bg-0f1d22">
    <div class="container">
        <div class="tab about-list-tab-2">
            <div class="row align-items-center">
                <div class="col-lg-2 col-md-12">
                    <div class="tabs-list">
                        <ul class="tabs">
                            <li>About</li>
                            <li>Skills</li>
                            <li>Awards</li>
                            <li>Experience</li>
                            <li>Education</li>
                            <li>Certification</li>
                            <li>Interview</li>
                            <li>Social</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-10 col-md-12">
                    <div class="tab-content">
                        <div class="tabs-item">
                            <div class="about-inner-area">
                                <div class="row align-items-center">
                                    <div class="col-lg-7 col-md-12">
                                        <div class="about-content">
                                            <span class="sub-title">About Me</span>
                                            <h2>Ushering into a Wonderlust and Photogenic Life</h2>
                                            <p>This an era of living in the moment and capturing the simplest of moment, the unforgettable one and the wonder of nature. I am here to offer you all professional yet artistic moments captured. Starting from classy, artistic event photography, I also capture scenes that behold and mesmerize eyes.</p>
                                            <a href="#contact" class="default-btn">Hire Me</a>
                                        </div>
                                    </div>
        
                                    <div class="col-lg-5 col-md-12">
                                        <div class="about-image">
                                            <img src="assets/img/about-img2.jpg" alt="image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="skills-area">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Landscape Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="85">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="85"></div></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Wildlife Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="90">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="90"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Aerial photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="87">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="87"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Sports Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="92">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="92"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Action Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="77">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="77"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Portrait Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="75">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="75"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Architectural Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="80">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="80"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="skills-item">
                                            <div class="skills-header">
                                                <h3 class="skills-title">Wedding Photography</h3>
        
                                                <div class="skills-percentage">
                                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="85">0</span>%</div>
                                                </div>
                                            </div>
        
                                            <div class="skills-bar">
                                                <div class="bar-inner"><div class="bar progress-line" data-width="85"></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="awards-area">
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img1.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img2.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img3.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img4.png" alt="image">
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img5.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img6.png" alt="image">
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img7.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-awards-box">
                                            <img src="assets/img/awards/img8.png" alt="image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="experience-area">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-experience-box">
                                            <span>2010 - 2012 (Photographer)</span>
                                            <h3>Primary Group, Inc.</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-experience-box">
                                            <span>2012 - 2015 (Photographer)</span>
                                            <h3>EnvyTheme Co.</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-experience-box">
                                            <span>2015 - 2018 (Photographer)</span>
                                            <h3>Envato Co.</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-experience-box">
                                            <span>2018 - 2020 (Photographer)</span>
                                            <h3>Themeforest Co.</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="education-area">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-education-box">
                                            <span>2010 - 2012 (Photography)</span>
                                            <h3>Stanford University</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-education-box">
                                            <span>2010 - 2012 (Bachelor's Degree)</span>
                                            <h3>Harvard University</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-education-box">
                                            <span>2010 - 2012 (Photography)</span>
                                            <h3>Columbia University</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-education-box">
                                            <span>2010 - 2012 (Photography)</span>
                                            <h3>Stanford University</h3>
                                            <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="certification-area">
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img1.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img2.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img3.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img4.png" alt="image">
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img5.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img6.png" alt="image">
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img7.png" alt="image">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                                        <div class="single-certification-box">
                                            <img src="assets/img/certification/img8.png" alt="image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="interview-area">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-interview-video">
                                            <img src="assets/img/interview/img1.jpg" alt="image">
                                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"></a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-interview-video">
                                            <img src="assets/img/interview/img2.jpg" alt="image">
                                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"></a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-interview-video">
                                            <img src="assets/img/interview/img3.jpg" alt="image">
                                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"></a>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-interview-video">
                                            <img src="assets/img/interview/img4.jpg" alt="image">
                                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"></a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-interview-video">
                                            <img src="assets/img/interview/img5.jpg" alt="image">
                                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"></a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-interview-video">
                                            <img src="assets/img/interview/img6.jpg" alt="image">
                                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="social-area">
                                <ul>
                                    <li><a href="#" class="facebook"><i class="ri-facebook-fill"></i><span> - Facebook</span></a></li>
                                    <li><a href="#" class="twitter"><i class="ri-twitter-line"></i><span> - Twitter</span></a></li>
                                    <li><a href="#" class="linkedin"><i class="ri-linkedin-fill"></i><span> - Linkedin</span></a></li>
                                    <li><a href="#" class="instagram"><i class="ri-instagram-line"></i><span> - Instagram</span></a></li>
                                    <li><a href="#" class="dribbble"><i class="ri-dribbble-line"></i><span> - Dribbble</span></a></li>
                                    <li><a href="#" class="behance"><i class="ri-behance-fill"></i><span> - Behance</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="services" class="services-area-two pt-120 pb-90 bg-0f1d22">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>I Offer Awesome Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="ri-camera-line"></i>
                    </div>
                    <h3>Landscape Photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur cing elit, sed do eiusmod tempor incididunt uttt labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="details-btn"><i class="ri-arrow-right-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="ri-camera-2-line"></i>
                    </div>
                    <h3>Wildlife Photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur cing elit, sed do eiusmod tempor incididunt uttt labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="details-btn"><i class="ri-arrow-right-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="ri-camera-3-line"></i>
                    </div>
                    <h3>Aerial photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur cing elit, sed do eiusmod tempor incididunt uttt labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="details-btn"><i class="ri-arrow-right-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="ri-polaroid-line"></i>
                    </div>
                    <h3>Sports Photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur cing elit, sed do eiusmod tempor incididunt uttt labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="details-btn"><i class="ri-arrow-right-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="ri-camera-switch-line"></i>
                    </div>
                    <h3>Wedding Photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur cing elit, sed do eiusmod tempor incididunt uttt labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="details-btn"><i class="ri-arrow-right-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="ri-vidicon-line"></i>
                    </div>
                    <h3>Fashion Photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur cing elit, sed do eiusmod tempor incididunt uttt labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="details-btn"><i class="ri-arrow-right-line"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area pt-120 pb-90 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-briefcase-line"></i>
                    <p>Projects Completed</p>
                    <h3 class="odometer" data-count="5421">00</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-group-2-line"></i>
                    <p>Happy Clients</p>
                    <h3 class="odometer" data-count="4579">00</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-cup-line"></i>
                    <p>Cup of Coffee</p>
                    <h3 class="odometer" data-count="499">00</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-trophy-line"></i>
                    <p>My Awards</p>
                    <h3 class="odometer" data-count="52">00</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section id="portfolio" class="works-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Works</span>
            <h2>Some of My Creative Projects</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="works-box">
                    <div class="works-image">
                        <a routerLink="/works-details">
                            <img src="assets/img/photography-works/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Festival Banner</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Banner</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="works-box">
                    <div class="works-image">
                        <a routerLink="/works-details">
                            <img src="assets/img/photography-works/img2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Wedding Cover Presentation</a></h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Cover</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="works-box">
                    <div class="works-image">
                        <a routerLink="/works-details">
                            <img src="assets/img/photography-works/img3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Wildlife Vector</a></h3>
                        <ul>
                            <li><a href="#">Lion</a></li>
                            <li><a href="#">Photo</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="works-box">
                    <div class="works-image">
                        <a routerLink="/works-details">
                            <img src="assets/img/photography-works/img4.jpg" alt="image">
                        </a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Fashion Design</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Design</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="works-box">
                    <div class="works-image">
                        <a routerLink="/works-details">
                            <img src="assets/img/photography-works/img5.jpg" alt="image">
                        </a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Digital Photography</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Photo</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="feedback-content jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="section-title">
                                <span class="sub-title">Testimonials</span>
                                <h2>What my clients say about me</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="feedback-slides-content lr-padding bg-0f1d22">
                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback-item">
                            <i class="ri-double-quotes-r"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Jason Statham</h3>
                                <span>Web Developer</span>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <i class="ri-double-quotes-r"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Sarah Taylor</h3>
                                <span>Web Designer</span>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <i class="ri-double-quotes-r"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>David Warner</h3>
                                <span>PHP Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="my-clients-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Clients</span>
            <h2>My Honoranle Clients</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img1.png" alt="image">
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img2.png" alt="image">
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img3.png" alt="image">
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img4.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img5.png" alt="image">
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img6.png" alt="image">
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img7.png" alt="image">
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                <div class="single-clients">
                    <a href="#">
                        <img src="assets/img/clients/img8.png" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="hire-me-area bg-0f1d22 ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="hire-me-content">
                    <span class="sub-title">Have You Any Project?</span>
                    <h2>I am Available for Freelance Hire</h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="hire-me-btn">
                    <a href="#contact" class="default-btn">Hire Me</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="blog-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Get to Know Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img4.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-tag">
                            <li><a href="#">Wordpress</a></li>
                            <li><a href="#">SEO</a></li>
                            <li><a href="#">Marketing</a></li>
                        </ul>

                        <h3><a routerLink="/blog-details">Olympus OM-D E-M1 Mark III Sample Images</a></h3>

                        <div class="post-meta">
                            <ul>
                                <li>By <a href="#">John</a></li>
                                <li>21 February, 2020</li>
                            </ul>
                            <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img5.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-tag">
                            <li><a href="#">HTML</a></li>
                            <li><a href="#">CSS</a></li>
                        </ul>

                        <h3><a routerLink="/blog-details">Sigma 24-70mm F2.8 DG DN Art Review</a></h3>

                        <div class="post-meta">
                            <ul>
                                <li>By <a href="#">John</a></li>
                                <li>20 February, 2020</li>
                            </ul>
                            <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img6.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-tag">
                            <li><a href="#">JS</a></li>
                            <li><a href="#">PHP</a></li>
                        </ul>

                        <h3><a routerLink="/blog-details">Connect, Share and Store with image.canon</a></h3>

                        <div class="post-meta">
                            <ul>
                                <li>By <a href="#">John</a></li>
                                <li>19 February, 2020</li>
                            </ul>
                            <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="contact-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-content jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="section-title">
                                <span class="sub-title">Let's talk</span>
                                <h2>Get in Touch With Me</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form lr-padding bg-0f1d22">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Enter your name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Enter your email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Enter your message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer-area">
    <div class="container">
        <div class="footer-content">
            <div class="logo">
                <a routerLink="/home-twenty">
                    <img src="assets/img/logo.png" alt="image">
                </a>
            </div>

            <div class="footer-contact-info">
                <ul>
                    <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">27 Division St, New York, NY 10002, USA</a></li>
                    <li><i class="ri-phone-line"></i> <a href="tel:+321984754">(+321) 984 754</a></li>
                    <li><i class="ri-mail-line"></i> <a href="mailto:hello@placid.com">hello@placid.com</a></li>
                </ul>
            </div>

            <ul class="social">
                <li><a href="#" target="_blank">Facebook</a></li>
                <li><a href="#" target="_blank">Twitter</a></li>
                <li><a href="#" target="_blank">Linkedin</a></li>
                <li><a href="#" target="_blank">Dribbble</a></li>
                <li><a href="#" target="_blank">Instagram</a></li>
            </ul>
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <p>© Placid is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>

        <div class="line"></div>
    </div>
</footer>