<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.png" alt="image" />
            </a>
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link active" href="#home">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#about">About Me</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#skills">Skills</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="aside-footer">
            <a href="assets/pdf/VictorMartinCV.pdf" target="_blank" class="default-btn">Download CV</a>
            <ul class="social">
                <!-- <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li> -->
                <li>
                    <a href="https://www.linkedin.com/in/victor-martin-a1464397/" target="_blank"><i
                            class="ri-linkedin-box-fill"></i></a>
                </li>
                <li>
                    <a href="https://github.com/victormartingil" target="_blank"><i class="ri-github-fill"></i></a>
                </li>
                <li>
                    <a href="mailto:victormartingil@gmail.com" target="_blank"><i class="ri-mail-line"></i></a>
                </li>
            </ul>
        </div>
        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">
        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div id="home" class="main-banner banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <!-- <span class="sub-title">My name is</span> -->
                            <h1>Victor Martin</h1>
                            <h6>
                                I’m a
                                <span class="typewrite" data-period="2000"
                                    data-type='[ " Backend Developer", " Software Engineer", " Spring Boot Expert" ]'>
                                    <span class="wrap"></span>
                                </span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="about" class="about-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">About Me</span>
                    <h2>Senior Backend Developer</h2>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="about-content">
                            <p>
                                I am a personable and motivated backend
                                developer with outstanding interpersonal skills.
                                I love working in teams and collaborating with
                                others. What sets me apart is my eagerness to
                                learn and improve my compentencies. I always
                                pursue opportunities for frowth and improvement
                                with a dynamic and practice approach.
                            </p>

                            <div class="tab about-list-tab">
                                <ul class="tabs">
                                    <li>Skills</li>
                                    <!-- <li>Awards</li> -->
                                    <li>Experience</li>
                                    <li>Education</li>
                                    <!-- <li>Certification</li> -->
                                </ul>

                                <div class="tab-content">
                                    <div class="tabs-item">
                                        <div class="skills-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Spring Boot (DATA, CLOUD, SECURITY)
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="90">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="90"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Java 8, 11, 17
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="95">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="95"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                MySQL - POSTGRESQL - MongoDB
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="87">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="87"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                SOLID & CLEAN CODE
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="92">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="92"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                TDD (JUnit 5)
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="77">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="77"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Kafka - RabbitMQ
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="79">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="79"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Git, Maven,
                                                                Gradle
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="91">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="91"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Kibana, Grafana
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="85">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="85"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Docker,
                                                                Kubernetes,
                                                                Jenkins
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="80">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="80"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">
                                                                Agile (SCRUM)
                                                            </h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box">
                                                                    <span class="count-text" data-speed="2000"
                                                                        data-stop="94">0</span>%
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="94"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="experience-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>(2023-Present)
                                                            Senior Backend
                                                            Developer</span>
                                                        <h3>OrangeBank</h3>
                                                        <p>
                                                            Spring Boot, Java
                                                            17, PostgreSQL, TDD, Kafka
                                                            Maven, Git, CI/CD, Jenkins, Docker, K8, SCRUM
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>(2022)
                                                            Senior Backend
                                                            Developer</span>
                                                        <h3>Inditex</h3>
                                                        <p>
                                                            Spring Boot, Java
                                                            17, MongoDB, TDD,
                                                            Maven, Git, CI/CD, Github
                                                            Actions, Docker, K8, SCRUM
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>(2021)
                                                            Backend
                                                            Developer</span>
                                                        <h3>Mindcurv</h3>
                                                        <p>
                                                            Spring Boot, Java
                                                            11, MySQL, Docker,
                                                            Kubernetes, Jenkins,
                                                            Git, Maven
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>(2019 - 2020)
                                                            Backend
                                                            Developer</span>
                                                        <h3>
                                                            Imagar Solutions
                                                            Company
                                                        </h3>
                                                        <p>
                                                            Spring Boot, Java
                                                            8/11, Maven, Git,
                                                            SCRUM, Jenkis,
                                                            Docker, Kubernetes
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>(2018) FullStack
                                                            Developer</span>
                                                        <h3>Merkle</h3>
                                                        <p>
                                                            Spring Boot, Java 8,
                                                            Angular, MySQL, Git
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="education-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>(2018) Computer
                                                            Technician</span>
                                                        <h3>
                                                            Certificate of
                                                            Higher Education:
                                                            Multiplatform
                                                            Application
                                                            Developer
                                                        </h3>
                                                        <!-- <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>(2011) Audiovisual
                                                            Technician</span>
                                                        <h3>
                                                            Certificate of
                                                            Higher Education:
                                                            Audiovisual and
                                                            Entertainment
                                                            Production
                                                        </h3>
                                                        <!-- <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>(2011) French
                                                            Language</span>
                                                        <h3>
                                                            Advanced French
                                                            Language Course in
                                                            Paris (1 year in
                                                            person)
                                                        </h3>
                                                        <!-- <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>(2008) English
                                                            Language</span>
                                                        <h3>
                                                            Advanced English
                                                            Language Course in
                                                            London (1 year in
                                                            person)
                                                        </h3>
                                                        <!-- <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="certification-area">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img1.png" alt="image" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img2.png" alt="image" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img3.png" alt="image" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img4.png" alt="image" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img5.png" alt="image" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img6.png" alt="image" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/myPhotos/about-img1.jpg" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section id="skills" class="services-area pt-120 pb-90 bg-0f1d22">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Skills</span>
                    <h2>What about my main skills?</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="200" src="assets/img/logos/spring-boot.png" alt="image" />
                                <!-- <i class="flaticon-result"></i> -->
                            </div>
                            <h3>Microservices development</h3>
                            <p>
                                Proficient in leveraging Spring Boot to architect and engineer microservices, delivering scalable and robust solutions adhering to industry best practices and driving optimized system architecture.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="100" src="assets/img/logos/git.png" alt="image" />
                                <!-- <i class="flaticon-email"></i> -->
                            </div>
                            <h3>Git</h3>
                            <p>
                                Demonstrates adeptness in utilizing Git for efficient version control and collaborative software development, ensuring seamless code management and streamlined team collaboration.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="150" src="assets/img/logos/junit.png" alt="image" />
                                <!-- <i class="flaticon-content"></i> -->
                            </div>
                            <h3>TDD Approach</h3>
                            <p>
                                Proficient in employing a Test-Driven Development (TDD) methodology to deliver high-quality software solutions with enhanced stability and maintainability.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="300" src="assets/img/logos/databases.png" alt="image" />
                                <!-- <i class="flaticon-education"></i> -->
                            </div>
                            <h3>Databases</h3>
                            <p>
                                Proficient in working with diverse database management systems (MySQL, PostgreSQL, MongoDB), adeptly utilizing their respective functionalities to design and optimize efficient data models and storage solutions for robust and scalable applications.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="150" src="assets/img/logos/cloud.png" alt="image" />
                                <!-- <i class="flaticon-analysis"></i> -->
                            </div>
                            <h3>Cloud</h3>
                            <p>
                            Experienced in harnessing the power of cloud technologies, adeptly utilizing features and services (such as AWS, Azure, or GCP) to architect and develop scalable and resilient applications, ensuring efficient resource utilization, high availability, and seamless deployment.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="100" src="assets/img/logos/scrum.png" alt="image" />
                                <!-- <i class="flaticon-team"></i> -->
                            </div>
                            <h3>Agile (SCRUM)</h3>
                            <p>
                                Skilled in applying Agile methodologies, particularly SCRUM, to drive collaborative development, streamline workflows, and deliver high-quality software within dynamic environments.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>


                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="150" src="assets/img/logos/kafka.png" alt="image" />
                                <!-- <i class="flaticon-education"></i> -->
                            </div>
                            <h3>Reactivity</h3>
                            <p>
                                Proficient in building highly responsive applications using asynchronous development techniques and tools like Kafka.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="270" src="assets/img/logos/solid.png" alt="image" />
                                <!-- <i class="flaticon-analysis"></i> -->
                            </div>
                            <h3>Clean Code and SOLID</h3>
                            <p>
                                Strong advocate of clean code principles and SOLID design principles, ensuring maintainability and scalability of software projects.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <img width="200" src="assets/img/logos/dessign-patterns.png" alt="image" />
                                <!-- <i class="flaticon-team"></i> -->
                            </div>
                            <h3>Dessign Patterns</h3>
                            <p>
                                Experienced in utilizing a wide range of design patterns to develop robust and extensible software solutions.
                            </p>
                            <!-- <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a> -->
                        </div>
                    </div>

                </div>
            </div>
            <div class="my-clients-area ptb-120">
                <div class="container">
                    <div class="section-title">
                        <span class="sub-title">Clients</span>
                        <h2>My Honorable Clients</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div class="single-clients">
                                <a href="#">
                                    <img src="assets/img/clients/myPhotos/img1_.jpg" alt="image" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div class="single-clients">
                                <a href="#">
                                    <img src="assets/img/clients/myPhotos/img2_.jpg" alt="image" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div class="single-clients">
                                <a href="#">
                                    <img src="assets/img/clients/myPhotos/img3_.jpg" alt="image" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div class="single-clients">
                                <a href="#">
                                    <img src="assets/img/clients/myPhotos/img5_.jpg" alt="image" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="contact" class="contact-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="contact-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Let's talk</span>
                                        <h2>Get in Touch With Me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="contact-form bg-0f1d22">
                            <div class="col-lg-12 col-md-12">
                                <h3 class="mb-3" style="color: white">
                                    Check my CV
                                </h3>
                                <a href="assets/pdf/VictorMartinCV.pdf" type="submit" class="default-btn mb-5"
                                    target="_blank">
                                    Download CV
                                </a>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <h3 class="mb-3" style="color: white">
                                    Have you got any question?
                                </h3>
                                <a href="mailto:victormartingil@gmail.com" type="submit" class="default-btn">
                                    Send me an Email
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="image" />
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li>
                                <i class="ri-map-pin-line"></i>
                                <a href="https://www.google.com/maps/place/Madrid/@40.4381311,-3.8196197,11z/data=!3m1!4b1!4m6!3m5!1s0xd422997800a3c81:0xc436dec1618c2269!8m2!3d40.4167754!4d-3.7037902!16zL20vMDU2X3k?coh=164777&entry=tt"
                                    target="_blank">Madrid, SPAIN</a>
                            </li>
                            <li>
                                <i class="ri-phone-line"></i>
                                <a href="tel:+34644444859">(+34) 644 444 859</a>
                            </li>
                            <li>
                                <i class="ri-mail-line"></i>
                                <a href="mailto:victormartingil@gmail.com">Send me an email</a>
                            </li>
                        </ul>
                    </div>
                    <ul class="social">
                        <li>
                            <i class="ri-linkedin-box-fill"></i>
                            <a href="https://www.linkedin.com/in/victor-martin-a1464397/" target="_blank">Linkedin</a>
                        </li>
                        <li>
                            <i class="ri-github-fill"></i>
                            <a href="https://github.com/victormartingil" target="_blank">Github</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Copyright Victor Martin Website 2023</p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->
