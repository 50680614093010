import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-thirty-two',
  templateUrl: './home-thirty-two.component.html',
  styleUrls: ['./home-thirty-two.component.scss']
})
export class HomeThirtyTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
