<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/home-six">
                <img src="assets/img/logo.png" alt="image">
            </a>
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#articles">Articles</a></li>
                    <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                    <li class="nav-item"><a class="nav-link" href="#instagram">Instagram</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#" class="default-btn">Shop Now</a>

            <ul class="social">
                <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-dribbble-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="main-banner banner-bg9 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-content">
                            <span class="sub-title">Hobie Inflatable Life Jacket</span>
                            <h1>Placid <span>Out There</span></h1>
                            <p>Lorem ipsum dolor sit amet, aeque gloriatur delicatissimi est et, ex decore deseruisse scribentur his. Sea ne aeterno vivendum.</p>

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                                <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                                <li><a href="#" target="_blank"><i class="ri-linkedin-box-line"></i></a></li>
                                <li><a href="#" target="_blank"><i class="ri-dribbble-fill"></i></a></li>
                                <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section id="articles" class="projects-area pt-120 pb-90 bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img1.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Been Too Long On The Road</a></h3>
                                    <a href="#" class="category">Lifestyle</a>
                                </div>

                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img2.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Come On A Safari With Me</a></h3>
                                    <a href="#" class="category">Life</a>
                                </div>

                                <div class="date">
                                    <span>January 28, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img3.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Happiness Is Just Around The Bend</a></h3>
                                    <a href="#" class="category">World</a>
                                </div>

                                <div class="date">
                                    <span>January 27, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img4.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Having A Wonderful Time</a></h3>
                                    <a href="#" class="category">Calendar</a>
                                </div>

                                <div class="date">
                                    <span>January 26, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img5.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Journey To The Center Of The Earth</a></h3>
                                    <a href="#" class="category">Earth</a>
                                </div>

                                <div class="date">
                                    <span>January 25, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img6.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">The Beginning Of The End</a></h3>
                                    <a href="#" class="category">Tour</a>
                                </div>

                                <div class="date">
                                    <span>January 24, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img7.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Just Another Day In Paradise</a></h3>
                                    <a href="#" class="category">Day</a>
                                </div>

                                <div class="date">
                                    <span>January 23, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img8.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Halfway To Paradise</a></h3>
                                    <a href="#" class="category">Weather</a>
                                </div>

                                <div class="date">
                                    <span>January 22, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img9.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Midnight At The Oasis</a></h3>
                                    <a href="#" class="category">Night</a>
                                </div>

                                <div class="date">
                                    <span>January 21, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img10.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Don’t Give Up The Ship</a></h3>
                                    <a href="#" class="category">Lifestyle</a>
                                </div>

                                <div class="date">
                                    <span>January 20, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img11.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Foot Loose and Fancy Free s</a></h3>
                                    <a href="#" class="category">Tour</a>
                                </div>

                                <div class="date">
                                    <span>January 19, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-projects-box">
                            <img src="assets/img/backpacker-projects/img12.jpg" alt="image">

                            <div class="content">
                                <div class="inner">
                                    <h3><a routerLink="/blog-details">Getting There Is Half The Fun</a></h3>
                                    <a href="#" class="category">Travel</a>
                                </div>

                                <div class="date">
                                    <span>January 18, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="book-trainer-area pb-120 bg-0f1d22">
            <div class="container">
                <div class="book-trainer-content">
                    <h2>Let's do this!</h2>
                    <a href="#" class="default-btn">Shop Now!</a>
                </div>
            </div>
        </section>

        <section id="blog" class="blog-area pt-120 pb-90">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Blog</span>
                    <h2>My Latest Blogs</h2>
                </div>

                <div class="blog-slides owl-carousel owl-theme">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/img13.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-tag">
                                <li><a href="#">Travel</a></li>
                                <li><a href="#">Winter</a></li>
                            </ul>

                            <h3><a routerLink="/blog-details">Best places to visit in Europe this Winter</a></h3>

                            <div class="post-meta">
                                <ul>
                                    <li>By <a href="#">Alexander</a></li>
                                    <li>21 February, 2020</li>
                                </ul>
                                <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="single-blog-post">
                        <div class="post-image">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/img14.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-tag">
                                <li><a href="#">Tour</a></li>
                                <li><a href="#">Tips</a></li>
                            </ul>

                            <h3><a routerLink="/blog-details">Best places to stay in Koh Lanta, Thailand</a></h3>

                            <div class="post-meta">
                                <ul>
                                    <li>By <a href="#">Alexander</a></li>
                                    <li>20 February, 2020</li>
                                </ul>
                                <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="single-blog-post">
                        <div class="post-image">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/img15.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-tag">
                                <li><a href="#">Krabi</a></li>
                                <li><a href="#">Stay</a></li>
                            </ul>

                            <h3><a routerLink="/blog-details">Best places to stay in Krabi, Thailand</a></h3>

                            <div class="post-meta">
                                <ul>
                                    <li>By <a href="#">Alexander</a></li>
                                    <li>19 February, 2020</li>
                                </ul>
                                <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="single-blog-post">
                        <div class="post-image">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog/img16.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-tag">
                                <li><a href="#">Rent</a></li>
                                <li><a href="#">Scooter</a></li>
                            </ul>

                            <h3><a routerLink="/blog-details">Tips for renting a scooter in Thailand</a></h3>

                            <div class="post-meta">
                                <ul>
                                    <li>By <a href="#">Alexander</a></li>
                                    <li>19 February, 2020</li>
                                </ul>
                                <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="instagram" class="my-instagram-area pt-120 bg-0f1d22">
            <div class="container">
                <div class="instagram-title">
                    <a href="#" target="_blank"><i class="ri-instagram-line"></i> Follow us on @placid</a>
                </div>
            </div>
            <div class="instagram-slides owl-carousel owl-theme">
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img1.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>

                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img2.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>

                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img3.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img4.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img5.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img6.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img7.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img8.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img9.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/backpacker-instagram/img10.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/home-six">
                            <img src="assets/img/logo.png" alt="image">
                        </a>
                    </div>

                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">27 Division St, New York, NY 10002, USA</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+321984754">(+321) 984 754</a></li>
                            <li><i class="ri-mail-line"></i> <a href="mailto:hello@placid.com">hello@placid.com</a></li>
                        </ul>
                    </div>

                    <ul class="social">
                        <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li>
                        <li><a href="#" target="_blank">Linkedin</a></li>
                        <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li>
                    </ul>
                </div>
            </div>

            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Placid is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>

                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->